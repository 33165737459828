import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const PresentCampaignBasic = ({ location }) => {
  const title =
    "Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編"
          pagedesc="Twitterでプレゼント企画をする際に押さえておきたいポイントを紹介。実施の注意点や自社で使えるテンプレートも無料で公開しています。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/present-campaign-basic.jpg"
          pagepath="/blog/present-campaign-basic"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年10月10日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="プレゼントを受け渡ししている手"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/present-campaign-basic.jpg"
                className="w-full"
              />
            </figure>
            <p>
              Twitterで多く見かけるプレゼント企画は、認知拡大や集客にたいへん効果があります。うまく実施できれば、商品やブランドの魅力を広く伝えられるだけでなく、実際の売上向上にもつなげることができます。
            </p>
            <p>「でもプレゼント企画って何を準備すればいいの？」</p>
            <p>「プレゼント企画をやりたいけど、そんなに予算はかけられない…」</p>
            <p>
              そんな企業の方に向けて、Twitterプレゼント企画のやり方を、絶対に抑えるべきポイントに絞って解説します。
            </p>
            <p>
              この記事を読めば、失敗しないTwitterプレゼント企画が実施できるようになります。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterプレゼント企画のメリットとは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・商品やブランドの認知が拡大する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・フォロワーが増える
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        ・ECサイトや別のSNSへの流入が増える
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    Twitterプレゼント企画にはどんな種類がある？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・応募方法1：フォロー＆リツイート
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・応募方法2：フォロー＆いいね
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・応募方法3：ハッシュタグ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        ・抽選タイミング1：インスタントウィン（即時抽選）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・抽選タイミング2：後日抽選
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    Twitterプレゼント企画で注意すべきことは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・Twitterプレゼント企画注意点1：Twitter利用規約と送信上限
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        ・Twitterプレゼント企画注意点2：ユーザー目線のわかりやすさ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ・Twitterプレゼント企画注意点3：プレゼントの選定
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    Twitterプレゼント企画をいちばん簡単に始めるには
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        ・簡単に始めるプレゼント企画：応募方法
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        ・簡単に始めるプレゼント企画：実施期間
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        ・簡単に始めるプレゼント企画：プレゼント
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        ・簡単に始めるプレゼント企画：投稿テンプレート
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        ・簡単に始めるプレゼント企画：投稿後にするべきこと
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id21" className="toc__link">
                        ・簡単に始めるプレゼント企画：当選通知テンプレート（景品発送）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id22" className="toc__link">
                        ・簡単に始めるプレゼント企画：当選通知テンプレート（デジタルギフト）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id23" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">Twitterプレゼント企画のメリットとは？</h2>
              <p>
                企業がツイッターアカウントでプレゼント企画をするには、大きく3つのメリットがあります。
              </p>
            </div>
            <div>
              <h3 id="id2">商品やブランドの認知が拡大する</h3>
              <p>
                Twitterプレゼント企画をすれば、リツイートによる認知度アップが期待できます。リツイートを応募条件にしておけば、参加したユーザーをフォローしているユーザーのタイムラインにも、プレゼント企画ツイートが表示されます。拡散しやすい特徴を持ったTwitterで企業アカウントを持っているのなら、プレゼント企画をしない手はありません。
              </p>
            </div>
            <div>
              <h3 id="id3">フォロワーが増える</h3>
              <p>
                企業Twitterアカウントのフォローを応募の条件にしておけば、フォロワーの増加も期待できます。プレゼント企画をきっかけに商品やブランドを知ったユーザーが継続的にフォローし、さらには新しい顧客になってくれる可能性もあります。加えて、ある程度のフォロワー数がついているということは、そのアカウントへの信頼性にもつながります。
              </p>
              <div className="blog__border-highlight">
                <p>
                  <span className="emoji">&#128483;</span>
                  　PARKLoTマーケ担当のひとこと
                </p>
                <p>
                  「PARKLoTでは、1週間のプレゼント企画でフォロワーが5,000人増加したことも！」
                </p>
              </div>
            </div>
            <div>
              <h3 id="id4">ECサイトや別のSNSへの流入が増える</h3>
              <p>
                プレゼント企画をお知らせするツイート文言や結果通知DMには、自社ECサイトやLINEなどの他SNSリンクを含めることができます。こうすることで、参加ユーザーに直接商品の良さを伝えることができますし、複数チャネルでつながりを持つこともできます。オムニチャネルが注目される今、総合的に顧客にアプローチするスタート地点として、Twitterプレゼント企画は多数の企業で採用されています。
              </p>
            </div>
            <div>
              <h2 id="id5">Twitterプレゼント企画にはどんな種類がある？</h2>
              <p>
                Twitterプレゼント企画には、応募の方法が3種類、抽選タイミングが2種類あります。
              </p>
            </div>
            <div>
              <h3 id="id6">応募方法1：フォロー＆リツイート</h3>
              <p>
                フォロー＆リツイートキャンペーンは、指定されたTwitterアカウントをフォローした上で、指定されたキャンペーンツイートをリツイートすると応募が完了します。
              </p>
            </div>
            <div>
              <h3 id="id7">応募方法2：フォロー＆いいね</h3>
              <p>
                フォロー＆いいねキャンペーンは、指定されたTwitterアカウントをフォローした上で、指定されたキャンペーンツイートにいいねをすると応募が完了します。
              </p>
            </div>
            <div>
              <h3 id="id8">応募方法3：ハッシュタグ</h3>
              <p>
                ハッシュタグキャンペーンは、指定されたTwitterアカウントをフォローした上で、指定されたハッシュタグをつけて投稿をすると応募が完了します。
              </p>
            </div>
            <div>
              <h3 id="id9">抽選タイミング1：インスタントウィン（即時抽選）</h3>
              <p>
                フォロー＆リツイートなどの応募条件を完了すれば、「その場で当たる」のがインスタントウィン（即時抽選）です。すぐに結果がわかるうれしさでユーザーには人気ですが、実施には自動抽選ツールの導入が必要となります。
              </p>
              <div className="blog__border-highlight">
                <p>
                  PARKLoTで開催できるインスタントウィンプレゼント企画の詳細はこちら
                </p>
                <p>
                  ▶︎
                  <a
                    href="/case-study/twitter/instant-win-follow-and-retweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【対応キャンペーン】フォロー＆リツイート（インスタントウィン）
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h3 id="id10">抽選タイミング2：後日抽選</h3>
              <p>
                プレゼント企画の〆切り後に、手動で当選者を選び、結果を通知する運用方法です。応募条件をクリアしているかのチェックや個別に結果通知は必要になりますが、手動で小規模に始めるのには適した方法です。商品を愛用してくれそうなユーザーや、拡散が見込めそうなユーザーを選定できるのもメリットです。
              </p>
            </div>
            <div>
              <h2 id="id11">Twitterプレゼント企画で注意すべきことは？</h2>
              <p>
                Twitterでプレゼント企画を実施するにあたり、注意すべきこと3つを説明します。
              </p>
            </div>
            <div>
              <h3 id="id12">
                Twitterプレゼント企画注意点1：Twitter利用規約と送信上限
              </h3>
              <p>
                Twitterには、キャンペーンの実施についてのガイドラインがあります。
              </p>
              <ul className="blog__border-list">
                <li>複数アカウントを作成させない</li>
                <li>繰り返し同じツイートをさせない</li>
              </ul>
              <p>
                などのルールに違反しないようなプレゼント企画設計をしましょう。
              </p>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter キャンペーンの実施についてのガイドライン
                </a>
              </p>
              <p>
                また、TwitterにはDMとリプライの1日の送信件数に上限があります。大規模なプレゼント企画を実施する際は、DMは1,000通、リプライは2,400通の上限に気をつけてください。
              </p>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-limits"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter リミットについて
                </a>
              </p>
            </div>
            <div>
              <h3 id="id13">
                Twitterプレゼント企画注意点2：ユーザー目線のわかりやすさ
              </h3>
              <p>
                せっかく実施するプレゼント企画ですので、参加者から見てわかりやすい内容になっているかのチェックは必ず行います。
              </p>
              <ul className="blog__border-list">
                <li>プレゼント内容</li>
                <li>応募方法</li>
                <li>結果通知方法</li>
                <li>実施期間</li>
              </ul>
              <p>
                これらは、プレゼント企画ツイートと添付バナーの両方に明記しましょう。
              </p>
              <p>
                当選者のみに通知する（はずれたユーザには通知がない）ことも、忘れずに伝えておくとトラブルも起きにくいでしょう。
              </p>
              <p>
                また、鍵アカウントからの応募は無効にする、プレゼント発送先は国内に限定する、などにも注意が必要です。
              </p>
            </div>
            <div>
              <h3 id="id14">Twitterプレゼント企画注意点3：プレゼントの選定</h3>
              <p>
                プレゼント企画に参加するユーザーの立場から、うれしいプレゼントを考えましょう。送付の手間がかからず、もらってすぐに使えることから、AmazonギフトカードやQUOカードPayを利用する企業が多いようです。カート内クーポンコード入力で割引ができるECサイトを利用しているなら、そこで使えるクーポンの配布も効果的です。物理的な商品を景品にする場合は、当選者の氏名住所（個人情報）を収集し、発送作業をする必要があることも考慮しましょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id15">Twitterプレゼント企画をいちばん簡単に始めるには</h2>
            </div>
            <div>
              <h3 id="id16">簡単に始めるプレゼント企画：応募方法</h3>
              <p>
                フォロー＆リツートがおすすめです。企業アカウントをフォローし、プレゼント企画ツイートをリツートすることで応募できるので、参加が簡単です。リツイート数を把握できるので、開催企業にとっても管理しやすくなります。
              </p>
            </div>
            <div>
              <h3 id="id17">簡単に始めるプレゼント企画：実施期間</h3>
              <p>
                1週間程度で始めましょう。期間があまりにも短いと、参加者が集まりにくい場合があります。逆にあまりにも長い期間だと、応募を先延ばしにする心理がはたらいてしまいます。
              </p>
              <p>
                季節のイベントと合わせた開催で、ハッシュタグを効果的に利用するのもアリです。
              </p>
            </div>
            <div>
              <h3 id="id18">簡単に始めるプレゼント企画：プレゼント</h3>
              <p>
                特にフォロワーの少ない初期の段階では、Amazonギフトカード等のデジタルギフト券と、自社商品や自社サイトで使える割引デジタルクーポンを組み合わせた企画をおすすめしています。具体的には、１等が自社商品（物理発送）、２等がAmazonギフト券500円分を5名、などです。
              </p>
              <p>
                また、「ギフト券500円分を5名」と「ギフト券2,500円分を1名」では、当選人数の多い「ギフト券500円分を5名」の方が参加率が高い傾向にあります。
              </p>
              <div className="blog__border-highlight">
                <p>
                  <span className="emoji">&#128483;</span>
                  　PARKLoTマーケ担当のひとこと
                </p>
                <p>
                  「手動でDM結果通知をしたことがありますが、ミスなくできたのは15件が限界でした…」
                </p>
              </div>
            </div>
            <div>
              <h2 id="id19">簡単に始めるプレゼント企画・投稿テンプレート</h2>
              <div className="blog__border-highlight">
                <p>
                  ／<br />
                  PARKLoT<span className="emoji">&#127875;</span>
                  <br />
                  #ハロウィンキャンペーン <br />
                  ＼<br />
                  <br />
                  #フォローRTで当たる！
                  <br />
                  （製品名）を1名様
                  <span className="emoji">&#128171;</span>
                  <br />
                  Amazonギフト券500円分を 5名様にプレゼント
                  <span className="emoji">&#128171;</span>
                  <span className="emoji">&#128171;</span>
                  <br />
                  <br />
                  今すぐリツイートしてね！
                  <br />
                  <br />
                  【応募方法】
                  <br />
                  1. ＠PARKLoT1 をフォロー
                  <br />
                  2. この投稿をリツイート
                  <br />
                  【応募期間】 10/25 12:00 〜 10/31 21:00
                  <br />
                  結果は〆切後、当選者へのDM にてお知らせします。
                </p>
              </div>
            </div>
            <div>
              <h3 id="id20">
                簡単に始めるプレゼント企画：投稿後にするべきこと
              </h3>
              <p>
                プレゼント企画ツイートを投稿して、一安心してはいけません。該当ツイートを期間中にリツイートしたり、応募報告をしてくれたユーザーにいいねをつけたり、予算が許せばTwitter広告をかけることも検討しましょう。他のSNSアカウントでも、Twitterでプレゼント企画を開催していることをどんどん告知するのも大切です。
              </p>
              <div className="blog__border-highlight">
                <p>
                  <span className="emoji">&#128483;</span>
                  　PARKLoTマーケ担当のひとこと
                </p>
                <p>
                  「プレゼント企画投稿のリツイートは必須です！12時・19時頃が目安です！」
                </p>
              </div>
            </div>
            <div>
              <h3 id="id21">
                簡単に始めるプレゼント企画：当選通知テンプレート（景品発送）
              </h3>
              <p>
                プレゼント企画の〆切りがきたら、当選者を選び、プレゼントを送付しましょう。
              </p>
              <div className="blog__border-highlight">
                <p>
                  〇〇様
                  <br />
                  <br />
                  PARKLoTキャンペーン担当の尾崎と申します。
                  <br />
                  <br />
                  PARKLoTハロウィンキャンペーンへのご参加、ありがとうございます！
                  <br />
                  （製品名）に当選しました！おめでとうございます
                  <span className="emoji">&#127775;</span>
                  <span className="emoji">&#127775;</span>
                  <br />
                  <br />
                  さっそく、（製品名）をお送りしたいので、お手数ですが
                  <br />
                  ・お名前
                  <br />
                  ・郵便番号
                  <br />
                  ・ご住所
                  <br />
                  ・お電話番号
                  <br />
                  をこちらのDM へ返送いただけますでしょうか。
                  <br />
                  （11/7日までにお返事がない場合は当選無効となりますのでお気をつけください。）
                  <br />
                  <br />
                  PARKLoTでは、今後もワクワクするプレゼント企画を実施していきます。
                  <br />
                  他のキャンペーンは、
                  <a
                    href="https://goodluck.park-lot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://goodluck.park-lot.com/
                  </a>
                  からも参加できますので
                  <br />
                  ぜひチェックしてくださいね！
                </p>
              </div>
            </div>
            <div>
              <h3 id="id22">
                簡単に始めるプレゼント企画：当選通知テンプレート（デジタルギフト）
              </h3>
              <div className="blog__border-highlight">
                <p>
                  〇〇様
                  <br />
                  <br />
                  PARKLoTキャンペーン担当の尾崎と申します。
                  <br />
                  <br />
                  PARKLoTハロウィンキャンペーンへのご参加、ありがとうございます！
                  <br />
                  Amazonギフトカード500 円分に当選しました！おめでとうございます
                  <span className="emoji">&#127775;</span>
                  <span className="emoji">&#127775;</span>
                  <br />
                  <br />
                  <br />
                  コードはこちらです：
                  <br />
                  ーーーーーーーーーー
                  <br />
                  SAMPLE-CODE-NUMBER
                  <br />
                  ーーーーーーーーーー
                  <br />
                  <br />
                  PARKLoTでは、今後もワクワクするプレゼント企画を実施していきます。
                  <br />
                  他のキャンペーンは、
                  <a
                    href="https://goodluck.park-lot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://goodluck.park-lot.com/
                  </a>
                  からも参加できますので
                  <br />
                  ぜひチェックしてくださいね！
                  <br />
                  <br />
                  ※Amazonギフトコードの使用方法はこちらをご確認ください：
                  <br />
                  <a
                    href="https://www.amazon.co.jp/gp/help/customer/display.html?nodeId=G5D4TA7NBKQT7GW2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.amazon.co.jp/gp/help/customer/display.html?nodeId=G5D4TA7NBKQT7GW2
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id23">まとめ</h2>
              <p>
                本記事では、プレゼント企画のやり方について、メリットや注意点、実施テンプレートを交えて解説しました。
              </p>
              <p>
                Twitterプレゼント企画は、企業アカウントのフォロワー数を効率的に伸ばせるだけでなく、新しい顧客との接点を見つける機会になります。既存のフォロワーにもうれしい施策になりますので、企画の結果を振り返りながら、貴社の商品サービスにあったプレゼント企画を目指してみてください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/effective-communication"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterの効果的なコミュニケーション方法とは？
                    誰でも実践可能な顧客との付き合い方をレクチャー
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default PresentCampaignBasic
